// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$arr-name: 'arr';
$arr-x: 0px;
$arr-y: 0px;
$arr-offset-x: 0px;
$arr-offset-y: 0px;
$arr-width: 9px;
$arr-height: 5px;
$arr-total-width: 30px;
$arr-total-height: 26px;
$arr-image: 'sprite-_png.png';
$arr: (0px, 0px, 0px, 0px, 9px, 5px, 30px, 26px, 'sprite-_png.png', 'arr', );
$tel-icon-name: 'tel-icon';
$tel-icon-x: 12px;
$tel-icon-y: 8px;
$tel-icon-offset-x: -12px;
$tel-icon-offset-y: -8px;
$tel-icon-width: 18px;
$tel-icon-height: 18px;
$tel-icon-total-width: 30px;
$tel-icon-total-height: 26px;
$tel-icon-image: 'sprite-_png.png';
$tel-icon: (12px, 8px, -12px, -8px, 18px, 18px, 30px, 26px, 'sprite-_png.png', 'tel-icon', );
$spritesheet-width: 30px;
$spritesheet-height: 26px;
$spritesheet-image: 'sprite-_png.png';
$spritesheet-sprites: ($arr, $tel-icon, );
$spritesheet: (30px, 26px, 'sprite-_png.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
