//##############################################################################
// [[[[[ BEGIN ]]]]] UI-KIT
//##############################################################################

//==============================================================================
// [[[[ BEGIN ]]]] misc
//==============================================================================
.link-effect-2 {
  position: relative;
  margin-bottom: 3px;
  height: 30px;
  width: 100%;
  a {
    color: #d6f2ff;
    position: relative;
    line-height: 30px;
    padding-left: 25px;
    font-size: 10pt;
    display: block;
    background: url(../../img/icon/arr-footmenu.png) no-repeat 10px 50%;
    &:before {
      content: attr(data-hover);
      color:  $colorbluelight;
      position: absolute;
      top: 0;
      overflow: hidden;
      height: 100%;
      display: block;
      white-space: nowrap;
      padding-left: 25px;
      left: 0;
      z-index: 9;
      max-width: 0;
      -webkit-transition: max-width 0.5s;
      -moz-transition: max-width 0.5s;
      transition: max-width 0.5s;
    }
  }
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    left: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
  }
  &:before {
    background-color: $colorbluelight;
  }
  &:after {
    background-color: #d6f2ff;
    max-width: 0;
    -webkit-transition: max-width 0.5s;
    -moz-transition: max-width 0.5s;
    transition: max-width 0.5s;
  }
  &:hover {
    &:after {
      max-width: 100%;
    }
    a {
      &:before {
        max-width: calc(100% - 25px);
        background: url(../../img/icon/arr-footmenu-ha.png) no-repeat 10px 50%;
      }
    }
  }
}
// [[[[ END ]]]] misc
//==============================================================================

// [[[[[ BEGIN ]]]]] UI-KIT
//##############################################################################