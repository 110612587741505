//##############################################################################
// [[[[[ BEGIN ]]]]] ACCESSORY
//##############################################################################

//==============================================================================
// [[[[ BEGIN ]]]] accessory :: grid system
//==============================================================================

//------------------------------------------------------------------------------
// [[[ BEGIN ]]] accessory :: grid system :: constant
//------------------------------------------------------------------------------
$gs-_gutter-_half: 12px;

$_SCREEN-MOB-MAX: 767px;
$_SCREEN-SM-MIN: 768px;
$_SCREEN-TABLET-MAX: 991px;
$_SCREEN-MD-MIN: 992px;
$_SCREEN-LG-MIN: 1200px;
$_BASE-GRID-W: 1200px;
$_BASE-GRID-COL-PADDING: 12px;
$_SITE-W: $_BASE-GRID-W + $_BASE-GRID-COL-PADDING * 2;
// [[[ END ]]] accessory :: grid system :: constant
//------------------------------------------------------------------------------







//------------------------------------------------------------------------------
// [[[ BEGIN ]]] accessory :: grid system :: media query order
//------------------------------------------------------------------------------

/*

@media screen and (max-width: 1224px) {

}
@media screen and (max-width: 992px) {

}
@media screen and (max-width: 767px) {

}





@media screen and (max-width: 1920px) {

}
@media screen and (max-width: 1680px) {

}
@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1536px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {

}
@media screen and (max-width: 1024px) {

}
@media screen and (max-width: 800px) {

}
@media screen and (max-width: 768px) {

}
@media screen and (max-width: 640px) {

}
@media screen and (max-width: 540px) {

}
@media screen and (max-width: 480px) {

}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

}

*/

// [[[ END ]]] accessory :: grid system :: media query order
//------------------------------------------------------------------------------

// [[[[ END ]]]] accessory :: grid system
//==============================================================================










//==============================================================================
// [[[[ BEGIN ]]]] accessory :: color constant
//==============================================================================
$_COLOR_TEXT: #7e7e7e;
$colorlink: #565656;
$colorblue: #0f88bf;
$colorred: #f75543;
$colorbluelight: #1791c9;
$yellow :#ffdd00;
$green :#5bc83a;


$_COLOR_STYLE: #f9413e;
$_COLOR_GREEN: #84c004;
$_COLOR_STYLE-VARIANT: #ff6462;
// [[[[ END ]]]] accessory :: color constant
//==============================================================================










//==============================================================================
// [[[[ BEGIN ]]]] accessory :: typography constant
//==============================================================================

//------------------------------------------------------------------------------
// [[[ BEGIN ]]] accessory :: typography constant :: web-safe font family stack
//------------------------------------------------------------------------------
$_FONT-STACK_ARIAL: Arial, "Helvetica CY", "Helvetica Neue", Helvetica, "Nimbus Sans L", sans-serif;
$_FONT-STACK_TAHOMA: Tahoma, "Geneva CY", Verdana, Segoe, sans-serif;
$_FONT-STACK_TREBUCHET-MS: "Trebuchet MS", "Helvetica CY", "Lucida Grande", "Lucida Sans", Tahoma sans-serif;
$_FONT-STACK_VERDANA: Verdana, "Geneva CY", Geneva, "DejaVu Sans", sans-serif;
$_FONT-STACK_GEORGIA: Georgia, "Century Schoolbook L", Times, "Times New Roman", Serif;
$_FONT-STACK_TIMES-NEW-ROMAN: "Times New Roman", TimesNewRoman, "Times CY", Times, "Nimbus Roman No9 L", Baskerville, Georgia, serif;
// [[[ END ]]] accessory :: typography constant :: web-safe font family stack
//------------------------------------------------------------------------------







//------------------------------------------------------------------------------
// [[[ BEGIN ]]] accessory :: typography constant :: custom font family stack
//------------------------------------------------------------------------------
$sommetregular: sommet-regular;
$sommetbold: sommet-bold;
$sommeheavy: sommet-heavy;

$open-sans: 'Open Sans', sans-serif;
$demibold: demi-bold;
$deminormal: demi-normal;

$_FONT-MUSEO: 'museocyrl', Georgia, Serif ;
$_FONT-FIRESANS-REGULAR: FiraSans Regular,  Arial, sans-serif ;
// [[[ END ]]] accessory :: typography constant :: custom font family stack
//------------------------------------------------------------------------------







//------------------------------------------------------------------------------
// [[[ BEGIN ]]] accessory :: typography constant :: basic text
//------------------------------------------------------------------------------
$_BASE-V-SPACING: 20px;
// [[[ END ]]] accessory :: typography constant :: basic text
//------------------------------------------------------------------------------

// [[[[[ BEGIN ]]]]] ACCESSORY
//##############################################################################