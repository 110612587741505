//##############################################################################
// [[[[[ BEGIN ]]]]] PANE-BLOCK-9
//##############################################################################
.pane-block-9 {
  .front-afpromo {
    .slide-tell {
      margin-top: 17px;
  
      @media screen and (max-width: 1224px) {
        margin-top: 21px;
      }
  
      .tell-frblock.tell-left {
        @media screen and (max-width: 992px) {
          width: auto;
          padding-right: 0;
          float: none;
          text-align: center;
        }
    
        a {
          @media screen and (max-width: 992px) {
            text-align: center;
          }
        }
      }
    }
  }
}
// [[[[[ BEGIN ]]]]] PANE-BLOCK-9
//##############################################################################