//##############################################################################
// [[[[[ BEGIN ]]]]] HEADER
//##############################################################################
.link-effect1 {
  font-size: 10pt;
  position: relative;
  padding-bottom: 3px;
  display: inline-block;
  padding-right: 3px;
  padding-left: 3px;
  transition: color .3s;
  padding-top: 6px;
  color: #1492cc;
  &:before,
  &:after {
    height: 3px;
    background: #ebebeb;
    width: 100%;
    content: '';
    left: 0;
    position: absolute;
    display: block;
    top: 100%;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transform: scale(0.85);
    -moz-transform: scale(0.85);
    transform: scale(0.85);
  }
  &:after {
    opacity: 0;
    -webkit-transition: top 0.3s, opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: top 0.3s, opacity 0.3s, -moz-transform 0.3s;
    transition: top 0.3s, opacity 0.3s, transform 0.3s;
  }
  &:hover {
    color: $colorred;
    &:after,
    &:before {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      transform: scale(1);
    }
    &:after {
      top: 0%;
      opacity: 1;
    }
  }
}

.logo-effect {
  .logo-eff-visible {
    opacity: 1;
    transform: scale(1);
    transition: transform .3s, opacity .3s;
    -webkit-transition: transform .3s, opacity .3s;
  }
  
  .logo-eff-hidden {
    opacity: 0;
    transform: scale(0);
    transition: transform .3s, opacity .3s;
    -webkit-transition: transform .3s, opacity .3s;
  }
  &:hover {
    .logo-eff-visible {
      opacity: 0;
      transform: scale(0);
    }
    .logo-eff-hidden {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.link {
  color: $colorblue;
  font-size: 10pt;
  transition: color .3s;
  &:hover {
    color: $colorred;
  }
}





#header {
  @extend .container;
  margin-top: 25px;
  .row-main {
    margin-bottom: 25px;
    @extend .row;
    .pane-block-3 {
      @extend .col-sm-4;
      
      .block-logo {
        display: block;
        opacity: 1;
        cursor: pointer;
        transition: opacity .3s;
        @extend .logo-effect;
        img {
          float: left;
          margin-right: 15px;
        }
        .logo-ah {
          position: absolute;
          left: 12px;
        }
        .logo-text {
          color: $colorlink;
          font-size: 11pt;
          line-height: 13pt;
          span {
            display: block;
          }
        }
      }
    }
    .pane-block-1 {
      @extend .col-sm-4;
      width: 36%;
      
      .block-tel {
        width: 44%;
        float: left;
        
        .tel-visible {
          color: #a2a2a2;
          font-size: 13pt;
          padding-bottom: 3px;
          padding-right: 20px;
          position: relative;
          
          span {
            color: #565656;
            font-family: $sommetbold;
            display: inline-block;
            margin-right: 4px;
          }
          
          &:before {
            position: absolute;
            width: 115px;
            background: #ebebeb;
            display:  block;
            height: 3px;
            bottom: 0;
            content:'';
          }
          &:after {
            position: absolute;
            content: '';
            right: 0;
            top: 10px;
            @include sprite($arr);
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transition: transform .3s;
            -webkit-transition: -webkit-transform .3s;
            display: none;
          }
          &.active {
            &:after {
              transform: rotate(270deg);
              -webkit-transform: rotate(270deg)
            }
          }
        }
        .tel-hidden {
          position: absolute;
          left: 2px;
          top: 24px;
          z-index: 999;
          padding: 10px 18px;
          display: none;
          background: #fff;
          box-shadow: 0 0 10px rgba(66, 71, 75, .5);
          -webkit-box-shadow: 0 0 10px rgba(66, 71, 75, .5);
          -moz-box-shadow: 0 0 10px rgba(66, 71, 75, .5);
          a {
            display: block;
            color: #a2a2a2;
            font-size: 13pt;
            padding-right: 0;
            padding-bottom: 3px;
            position: relative;
            span {
              color: #565656;
              font-family: $sommetbold;
            }
          }
        }
        .colorbox-node {
          margin-top: 10px;
          display: block;
          @extend .link;
        }
      }
      .block-grafic {
        width: 26%;
        border-left: 1px solid #d6d6d6;
        float: left;
        padding-left: 12px;
        font-size: 10pt;
        .without-weekend {
          color: $colorred;
        }
        .time {
          color: #a2a2a2;
          span {
            color: #565656;
            font-family: $sommetbold;
          }
        }
      }
      .block-map {
        width: 30%;
        float: left;
        border-left: 1px solid #d6d6d6;
        
        a {
          padding-left: 50px;
          background: url(../../img/icon/pin.png) no-repeat 15px 50%;
          color: $colorblue;
          font-size: 10pt;
          line-height: 13pt;
          transition: color .3s;
          display: block;
          padding-top: 4px;
          min-height: 38px;
          
          &:hover {
            color: $colorred;
          }

        }
      }
    }
    .pane-menu-menu-submenu {
      width: auto;
      float: left;
      
      .menu {
        margin-left: 20px;
        padding-top: 4px;
        
        li {
          display: inline-block;
          a {
            @extend .link-effect1;
          }
        }
      }
    }
    .pane-locale-language {
      width: 54px;
      float: right;
      padding-left: $gs-_gutter-_half;
      padding-right: $gs-_gutter-_half;
      font-size: 14px;
      line-height: 20px;
  
      @media screen and (max-width: 992px) {
        position: absolute;
        top: 0;
        right: 40px;
      }
      @media screen and (max-width: 480px) {
        font-size: 12px;
        line-height: 16px;
        right: 30px;
      }
      
      ul.language-switcher-locale-url {
        list-style: none outside none;
        padding: 0;
        margin: 0;
      }
        li {
          list-style: none outside none;
          padding: 0;
          margin: 0;
          
          &.active {}
          
          & + li {
            margin-top: 4px;
  
            @media screen and (max-width: 992px) {
              margin-top: 2px;
            }
          }
        }
          a.language-link {
            color: #0f88bf;
            text-decoration: underline;
            
            &:hover {
              text-decoration: none;
            }
            
            &.active {
              color: #f75543;
              text-decoration: none;
              pointer-events: none;
            }
          }
    }
  }
  .row-bottom {
    .menu {
      overflow: hidden;
      li {
        float: left;
        width: 14.28%;
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        position: relative;
        height: 155px;
        border-right: 2px solid #fff;
        &:before {
          content: '';
          background-color: $colorblue;
          z-index: 0;
          top: 0;
          left: 0;
          width: 100%;
          position: absolute;
          height: 100%;
        }
        &:after {
          content: '';
          background-color: $colorred;
          z-index: 0;
          top: 0;
          left: 0;
          width: 100%;
          right: 0;
          bottom: 0;
          transform: scale(0);
          -webkit-transform: scale(0);
          margin: auto;
          border-radius: 50%;
          position: absolute;
          height: 100%;
          transition: transform .3s;
          -webkit-transition: -webkit-transform .3s;
        }
        a {
          display: block;
          padding-top: 90px;
          color: #fff;
          padding-left: 27px;
          padding-right: 27px;
          text-align: center;
          z-index: 9;
          height: 155px;
          overflow: hidden;
          font-size: 11pt;
          transition: all .2s;
          line-height: 13pt;
          background-position: 50%  20px ;
          position: relative;
        }
        &:last-child {
          border-right: 0;
        }
        &:hover {
          &:after{
            transform: scale(1.5);
          }
          a {
            background-position: 50% 22px;
            padding-top: 86px;
          }
        }
      }
    }
  }
}
.tel-icon {
  //@extend .compassicons-tel-icon;
  @include sprite($tel-icon);
  display: none;
}

@media (max-width: 1224px) {
  #header {
    .row-main {
      .pane-block-3 {
        width: 29%;
        
        .block-logo {
          img {
            margin-bottom: 10px;
          }
        }
      }
      .pane-block-1 {
        width: 35%;
        
        .block-tel {
          width: 42%;
        }
        .block-grafic {
          width: 30%;
          border-left: 0;
        }
        .block-map {
          width: 28%;
          border-left: 0;
        }
      }
    }
    .row-bottom {
      .menu {
        li {
          a {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .icon-menu {
    float: right;
    width: 30px;
    height: 23px;
    position: relative;
    z-index: 9;
    margin: 9px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: $colorblue;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2){
        top: 9px;
      }
      &:nth-child(3){
        top: 9px;
      }
      &:nth-child(4){
        top: 18px;
      }
    }
    &.open {
      span {
        background: $colorred;
        &:nth-child(1){
          top: 9px;
          width: 0%;
          left: 50%;
        }
        &:nth-child(2){
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:nth-child(3){
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &:nth-child(4){
          top: 9px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
  #header {
    .row-main {
      margin-bottom: 10px;
      
      .pane-block-3 {
        width: 38%;
      }
      .pane-block-1 {
        width: 62%;
        
        .block-tel{
          width: auto;
        }
        .block-grafic {
          width: auto;
        }
        .block-map {
          width: 120px;
          margin-left: 10px;
        }
      }
      .pane-menu-menu-submenu {
        width: auto;
        display: none;
        right: 0;
        padding: 10px 25px 26px 25px;
        position: absolute;
        z-index: 999;
        -moz-box-shadow: 0 1px 10px rgba(66,71,75,.5);
        -webkit-box-shadow: 0 1px 10px rgba(66,71,75,.5);
        box-shadow: 0 1px 10px rgba(66,71,75,.5);
        top: 50px;
        background: #fff;
        .menu {
          li {
            display: block;
            a {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
    .row-bottom {
      .menu {
        li {
          height: 140px;
          a {
            padding-left: 5px;
            padding-right: 5px;
            background-size: 37%;
            padding-top: 82px;
            font-size: 10pt;
            height: 140px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #header {
    .icon-menu {
      margin-bottom: 0;
      margin-top: 8px;
    }
    .row-main {
      .pane-block-3 {
        float: left;
        width: 140px;
        
        .block-logo {
          img {
            width: 100%;
            max-width: 115px;
            height: auto;
          }
          .logo-text {
            display: none;
          }
        }
      }
      .pane-block-1 {
        float: left;
        width: calc(100% - 140px);
        
        .block-tel {
          margin-top: 5px;
          
          .tel-visible {
            display: none;
          }
          .tel-icon {
            display: block;
            opacity: 1;
            transition: opacity .33s;
            
            &.active {
              opacity: .7
            }
          }
          .colorbox-node {
            margin-top: 5px;
          }
        }

        .block-map {
          width: auto;
          
          a {
            font-size: 0;
          }
        }
        .block-grafic {
          display: none;
        }
      }
      .pane-menu-menu-submenu {
        display: none!important;
      }
    }
    .row-bottom {
      .pane-system-main-menu {
        display: none;
      }
      .menu {
        li {
          height: auto;
          width: 100%;
          text-align: left;
          border-right: 0;
          border-bottom: 3px solid #fff;
          a {
            padding-top: 0;
            padding-left: 65px;
            min-height: 50px;
            background-position: 20px 50%;
            background-size: 28px;
            height: auto;
            line-height: 50px;
            text-align: left;
          }
          &:hover {
            a {
              background-position: 20px 50%;
              padding-top: inherit;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  #header {
    .row-main {
      .pane-block-3 {
        width: 100px;
      }
      .pane-block-1 {
        width: calc(100% - 100px);
  
        .block-tel {
          margin-left: 10px;
          
          .tel-icon {
            display: none;
          }
          .colorbox-node {
            @include sprite($tel-icon);
            font-size: 0;
            line-height: 0;
            color: transparent;
            margin-top: 0;
          }
        }
        .block-map {
          margin-left: 20px;
          
          a {
            padding: 0;
            min-height: 0;
            width: 18px;
            height: 26px;
            background-position: 0 0;
            background-size: contain;
          }
        }
      }
    }
  }
}
// [[[[[ BEGIN ]]]]] HEADER
//##############################################################################