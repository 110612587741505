/*

@media screen and (max-width: 1224px) {

}
@media screen and (max-width: 992px) {

}
@media screen and (max-width: 767px) {

}





@media screen and (max-width: 1920px) {

}
@media screen and (max-width: 1680px) {

}
@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1536px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {

}
@media screen and (max-width: 1024px) {

}
@media screen and (max-width: 800px) {

}
@media screen and (max-width: 768px) {

}
@media screen and (max-width: 640px) {

}
@media screen and (max-width: 540px) {

}
@media screen and (max-width: 480px) {

}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

}

*/
.container, #footer #block-panels-mini-footer, #header {
  margin-right: auto;
  margin-left: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 768px) {
  .container, #footer #block-panels-mini-footer, #header {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container, #footer #block-panels-mini-footer, #header {
    width: 970px;
  }
}
@media (min-width: 1224px) {
  .container, #footer #block-panels-mini-footer, #header {
    width: 1174px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 12px;
  padding-right: 12px;
}

.row, #header .row-main {
  margin-left: -12px;
  margin-right: -12px;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
#header .row-main .pane-block-1,
#header .row-main .pane-block-3,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, #header .row-main .pane-block-1, #header .row-main .pane-block-3, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4, #header .row-main .pane-block-1, #header .row-main .pane-block-3 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.66666667%;
  }

  .col-sm-pull-10 {
    right: 83.33333333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.66666667%;
  }

  .col-sm-pull-7 {
    right: 58.33333333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.66666667%;
  }

  .col-sm-pull-4 {
    right: 33.33333333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.66666667%;
  }

  .col-sm-pull-1 {
    right: 8.33333333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.66666667%;
  }

  .col-sm-push-10 {
    left: 83.33333333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.66666667%;
  }

  .col-sm-push-7 {
    left: 58.33333333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.66666667%;
  }

  .col-sm-push-4 {
    left: 33.33333333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.66666667%;
  }

  .col-sm-push-1 {
    left: 8.33333333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66666667%;
  }

  .col-md-10 {
    width: 83.33333333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.66666667%;
  }

  .col-md-1 {
    width: 8.33333333%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.66666667%;
  }

  .col-md-pull-10 {
    right: 83.33333333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.66666667%;
  }

  .col-md-pull-7 {
    right: 58.33333333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.66666667%;
  }

  .col-md-pull-4 {
    right: 33.33333333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.66666667%;
  }

  .col-md-pull-1 {
    right: 8.33333333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.66666667%;
  }

  .col-md-push-10 {
    left: 83.33333333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.66666667%;
  }

  .col-md-push-7 {
    left: 58.33333333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.66666667%;
  }

  .col-md-push-4 {
    left: 33.33333333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.66666667%;
  }

  .col-md-push-1 {
    left: 8.33333333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 1224px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.66666667%;
  }

  .col-lg-10 {
    width: 83.33333333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.66666667%;
  }

  .col-lg-7 {
    width: 58.33333333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.66666667%;
  }

  .col-lg-4 {
    width: 33.33333333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.66666667%;
  }

  .col-lg-1 {
    width: 8.33333333%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.66666667%;
  }

  .col-lg-pull-10 {
    right: 83.33333333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.66666667%;
  }

  .col-lg-pull-7 {
    right: 58.33333333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.66666667%;
  }

  .col-lg-pull-4 {
    right: 33.33333333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.66666667%;
  }

  .col-lg-pull-1 {
    right: 8.33333333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.66666667%;
  }

  .col-lg-push-10 {
    left: 83.33333333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.66666667%;
  }

  .col-lg-push-7 {
    left: 58.33333333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.66666667%;
  }

  .col-lg-push-4 {
    left: 33.33333333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.66666667%;
  }

  .col-lg-push-1 {
    left: 8.33333333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }
}
.container:before, #footer #block-panels-mini-footer:before, #header:before,
.container:after,
#footer #block-panels-mini-footer:after,
#header:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
#header .row-main:before,
.row:after,
#header .row-main:after {
  content: " ";
  display: table;
}

.container:after, #footer #block-panels-mini-footer:after, #header:after,
.container-fluid:after,
.row:after,
#header .row-main:after {
  clear: both;
}

@-ms-viewport {
  width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs,
td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm,
td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md,
td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg,
td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print,
td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
.link-effect-2, #footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-content li {
  position: relative;
  margin-bottom: 3px;
  height: 30px;
  width: 100%;
}
.link-effect-2 a, #footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-content li a {
  color: #d6f2ff;
  position: relative;
  line-height: 30px;
  padding-left: 25px;
  font-size: 10pt;
  display: block;
  background: url(../../img/icon/arr-footmenu.png) no-repeat 10px 50%;
}
.link-effect-2 a:before, #footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-content li a:before {
  content: attr(data-hover);
  color: #1791c9;
  position: absolute;
  top: 0;
  overflow: hidden;
  height: 100%;
  display: block;
  white-space: nowrap;
  padding-left: 25px;
  left: 0;
  z-index: 9;
  max-width: 0;
  -webkit-transition: max-width 0.5s;
  -moz-transition: max-width 0.5s;
  transition: max-width 0.5s;
}
.link-effect-2:after, #footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-content li:after, .link-effect-2:before, #footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-content li:before {
  content: "";
  position: absolute;
  top: 0;
  display: block;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
}
.link-effect-2:before, #footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-content li:before {
  background-color: #1791c9;
}
.link-effect-2:after, #footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-content li:after {
  background-color: #d6f2ff;
  max-width: 0;
  -webkit-transition: max-width 0.5s;
  -moz-transition: max-width 0.5s;
  transition: max-width 0.5s;
}
.link-effect-2:hover:after, #footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-content li:hover:after {
  max-width: 100%;
}
.link-effect-2:hover a:before, #footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-content li:hover a:before {
  max-width: calc(100% - 25px);
  background: url(../../img/icon/arr-footmenu-ha.png) no-repeat 10px 50%;
}

.link-effect1, #header .row-main .pane-menu-menu-submenu .menu li a {
  font-size: 10pt;
  position: relative;
  padding-bottom: 3px;
  display: inline-block;
  padding-right: 3px;
  padding-left: 3px;
  transition: color 0.3s;
  padding-top: 6px;
  color: #1492cc;
}
.link-effect1:before, #header .row-main .pane-menu-menu-submenu .menu li a:before, .link-effect1:after, #header .row-main .pane-menu-menu-submenu .menu li a:after {
  height: 3px;
  background: #ebebeb;
  width: 100%;
  content: "";
  left: 0;
  position: absolute;
  display: block;
  top: 100%;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  transform: scale(0.85);
}
.link-effect1:after, #header .row-main .pane-menu-menu-submenu .menu li a:after {
  opacity: 0;
  -webkit-transition: top 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: top 0.3s, opacity 0.3s, -moz-transform 0.3s;
  transition: top 0.3s, opacity 0.3s, transform 0.3s;
}
.link-effect1:hover, #header .row-main .pane-menu-menu-submenu .menu li a:hover {
  color: #f75543;
}
.link-effect1:hover:after, #header .row-main .pane-menu-menu-submenu .menu li a:hover:after, .link-effect1:hover:before, #header .row-main .pane-menu-menu-submenu .menu li a:hover:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}
.link-effect1:hover:after, #header .row-main .pane-menu-menu-submenu .menu li a:hover:after {
  top: 0%;
  opacity: 1;
}

.logo-effect .logo-eff-visible, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a .logo-eff-visible, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a img.im-logo, #header .row-main .pane-block-3 .block-logo .logo-eff-visible {
  opacity: 1;
  transform: scale(1);
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition: transform 0.3s, opacity 0.3s;
}
.logo-effect .logo-eff-hidden, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a .logo-eff-hidden, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a .im-logo-ah, #header .row-main .pane-block-3 .block-logo .logo-eff-hidden {
  opacity: 0;
  transform: scale(0);
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition: transform 0.3s, opacity 0.3s;
}
.logo-effect:hover .logo-eff-visible, .logo-effect:hover #footer #block-panels-mini-footer .pane-block-4 div.im-logo a img.im-logo, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a .logo-effect:hover img.im-logo, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a:hover .logo-eff-visible, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a:hover img.im-logo, #header .row-main .pane-block-3 .block-logo:hover .logo-eff-visible, #header .row-main .pane-block-3 .block-logo:hover #footer #block-panels-mini-footer .pane-block-4 div.im-logo a img.im-logo, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a #header .row-main .pane-block-3 .block-logo:hover img.im-logo {
  opacity: 0;
  transform: scale(0);
}
.logo-effect:hover .logo-eff-hidden, .logo-effect:hover #footer #block-panels-mini-footer .pane-block-4 div.im-logo a .im-logo-ah, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a .logo-effect:hover .im-logo-ah, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a:hover .logo-eff-hidden, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a:hover .im-logo-ah, #header .row-main .pane-block-3 .block-logo:hover .logo-eff-hidden, #header .row-main .pane-block-3 .block-logo:hover #footer #block-panels-mini-footer .pane-block-4 div.im-logo a .im-logo-ah, #footer #block-panels-mini-footer .pane-block-4 div.im-logo a #header .row-main .pane-block-3 .block-logo:hover .im-logo-ah {
  opacity: 1;
  transform: scale(1);
}

.link, #header .row-main .pane-block-1 .block-tel .colorbox-node {
  color: #0f88bf;
  font-size: 10pt;
  transition: color 0.3s;
}
.link:hover, #header .row-main .pane-block-1 .block-tel .colorbox-node:hover {
  color: #f75543;
}

#header {
  margin-top: 25px;
}
#header .row-main {
  margin-bottom: 25px;
}
#header .row-main .pane-block-3 .block-logo {
  display: block;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.3s;
}
#header .row-main .pane-block-3 .block-logo img {
  float: left;
  margin-right: 15px;
}
#header .row-main .pane-block-3 .block-logo .logo-ah {
  position: absolute;
  left: 12px;
}
#header .row-main .pane-block-3 .block-logo .logo-text {
  color: #565656;
  font-size: 11pt;
  line-height: 13pt;
}
#header .row-main .pane-block-3 .block-logo .logo-text span {
  display: block;
}
#header .row-main .pane-block-1 {
  width: 36%;
}
#header .row-main .pane-block-1 .block-tel {
  width: 44%;
  float: left;
}
#header .row-main .pane-block-1 .block-tel .tel-visible {
  color: #a2a2a2;
  font-size: 13pt;
  padding-bottom: 3px;
  padding-right: 20px;
  position: relative;
}
#header .row-main .pane-block-1 .block-tel .tel-visible span {
  color: #565656;
  font-family: sommet-bold;
  display: inline-block;
  margin-right: 4px;
}
#header .row-main .pane-block-1 .block-tel .tel-visible:before {
  position: absolute;
  width: 115px;
  background: #ebebeb;
  display: block;
  height: 3px;
  bottom: 0;
  content: "";
}
#header .row-main .pane-block-1 .block-tel .tel-visible:after {
  position: absolute;
  content: "";
  right: 0;
  top: 10px;
  background-image: url(sprite-_png.png);
  background-position: 0px 0px;
  width: 9px;
  height: 5px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transition: transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  display: none;
}
#header .row-main .pane-block-1 .block-tel .tel-visible.active:after {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}
#header .row-main .pane-block-1 .block-tel .tel-hidden {
  position: absolute;
  left: 2px;
  top: 24px;
  z-index: 999;
  padding: 10px 18px;
  display: none;
  background: #fff;
  box-shadow: 0 0 10px rgba(66, 71, 75, 0.5);
  -webkit-box-shadow: 0 0 10px rgba(66, 71, 75, 0.5);
  -moz-box-shadow: 0 0 10px rgba(66, 71, 75, 0.5);
}
#header .row-main .pane-block-1 .block-tel .tel-hidden a {
  display: block;
  color: #a2a2a2;
  font-size: 13pt;
  padding-right: 0;
  padding-bottom: 3px;
  position: relative;
}
#header .row-main .pane-block-1 .block-tel .tel-hidden a span {
  color: #565656;
  font-family: sommet-bold;
}
#header .row-main .pane-block-1 .block-tel .colorbox-node {
  margin-top: 10px;
  display: block;
}
#header .row-main .pane-block-1 .block-grafic {
  width: 26%;
  border-left: 1px solid #d6d6d6;
  float: left;
  padding-left: 12px;
  font-size: 10pt;
}
#header .row-main .pane-block-1 .block-grafic .without-weekend {
  color: #f75543;
}
#header .row-main .pane-block-1 .block-grafic .time {
  color: #a2a2a2;
}
#header .row-main .pane-block-1 .block-grafic .time span {
  color: #565656;
  font-family: sommet-bold;
}
#header .row-main .pane-block-1 .block-map {
  width: 30%;
  float: left;
  border-left: 1px solid #d6d6d6;
}
#header .row-main .pane-block-1 .block-map a {
  padding-left: 50px;
  background: url(../../img/icon/pin.png) no-repeat 15px 50%;
  color: #0f88bf;
  font-size: 10pt;
  line-height: 13pt;
  transition: color 0.3s;
  display: block;
  padding-top: 4px;
  min-height: 38px;
}
#header .row-main .pane-block-1 .block-map a:hover {
  color: #f75543;
}
#header .row-main .pane-menu-menu-submenu {
  width: auto;
  float: left;
}
#header .row-main .pane-menu-menu-submenu .menu {
  margin-left: 20px;
  padding-top: 4px;
}
#header .row-main .pane-menu-menu-submenu .menu li {
  display: inline-block;
}
#header .row-main .pane-locale-language {
  width: 54px;
  float: right;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  line-height: 20px;
}
@media screen and (max-width: 992px) {
  #header .row-main .pane-locale-language {
    position: absolute;
    top: 0;
    right: 40px;
  }
}
@media screen and (max-width: 480px) {
  #header .row-main .pane-locale-language {
    font-size: 12px;
    line-height: 16px;
    right: 30px;
  }
}
#header .row-main .pane-locale-language ul.language-switcher-locale-url {
  list-style: none outside none;
  padding: 0;
  margin: 0;
}
#header .row-main .pane-locale-language li {
  list-style: none outside none;
  padding: 0;
  margin: 0;
}
#header .row-main .pane-locale-language li + li {
  margin-top: 4px;
}
@media screen and (max-width: 992px) {
  #header .row-main .pane-locale-language li + li {
    margin-top: 2px;
  }
}
#header .row-main .pane-locale-language a.language-link {
  color: #0f88bf;
  text-decoration: underline;
}
#header .row-main .pane-locale-language a.language-link:hover {
  text-decoration: none;
}
#header .row-main .pane-locale-language a.language-link.active {
  color: #f75543;
  text-decoration: none;
  pointer-events: none;
}
#header .row-bottom .menu {
  overflow: hidden;
}
#header .row-bottom .menu li {
  float: left;
  width: 14.28%;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  height: 155px;
  border-right: 2px solid #fff;
}
#header .row-bottom .menu li:before {
  content: "";
  background-color: #0f88bf;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 100%;
}
#header .row-bottom .menu li:after {
  content: "";
  background-color: #f75543;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
  margin: auto;
  border-radius: 50%;
  position: absolute;
  height: 100%;
  transition: transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
}
#header .row-bottom .menu li a {
  display: block;
  padding-top: 90px;
  color: #fff;
  padding-left: 27px;
  padding-right: 27px;
  text-align: center;
  z-index: 9;
  height: 155px;
  overflow: hidden;
  font-size: 11pt;
  transition: all 0.2s;
  line-height: 13pt;
  background-position: 50% 20px;
  position: relative;
}
#header .row-bottom .menu li:last-child {
  border-right: 0;
}
#header .row-bottom .menu li:hover:after {
  transform: scale(1.5);
}
#header .row-bottom .menu li:hover a {
  background-position: 50% 22px;
  padding-top: 86px;
}

.tel-icon {
  background-image: url(sprite-_png.png);
  background-position: -12px -8px;
  width: 18px;
  height: 18px;
  display: none;
}

@media (max-width: 1224px) {
  #header .row-main .pane-block-3 {
    width: 29%;
  }
  #header .row-main .pane-block-3 .block-logo img {
    margin-bottom: 10px;
  }
  #header .row-main .pane-block-1 {
    width: 35%;
  }
  #header .row-main .pane-block-1 .block-tel {
    width: 42%;
  }
  #header .row-main .pane-block-1 .block-grafic {
    width: 30%;
    border-left: 0;
  }
  #header .row-main .pane-block-1 .block-map {
    width: 28%;
    border-left: 0;
  }
  #header .row-bottom .menu li a {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 992px) {
  .icon-menu {
    float: right;
    width: 30px;
    height: 23px;
    position: relative;
    z-index: 9;
    margin: 9px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }
  .icon-menu span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #0f88bf;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  .icon-menu span:nth-child(1) {
    top: 0px;
  }
  .icon-menu span:nth-child(2) {
    top: 9px;
  }
  .icon-menu span:nth-child(3) {
    top: 9px;
  }
  .icon-menu span:nth-child(4) {
    top: 18px;
  }
  .icon-menu.open span {
    background: #f75543;
  }
  .icon-menu.open span:nth-child(1) {
    top: 9px;
    width: 0%;
    left: 50%;
  }
  .icon-menu.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .icon-menu.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .icon-menu.open span:nth-child(4) {
    top: 9px;
    width: 0%;
    left: 50%;
  }

  #header .row-main {
    margin-bottom: 10px;
  }
  #header .row-main .pane-block-3 {
    width: 38%;
  }
  #header .row-main .pane-block-1 {
    width: 62%;
  }
  #header .row-main .pane-block-1 .block-tel {
    width: auto;
  }
  #header .row-main .pane-block-1 .block-grafic {
    width: auto;
  }
  #header .row-main .pane-block-1 .block-map {
    width: 120px;
    margin-left: 10px;
  }
  #header .row-main .pane-menu-menu-submenu {
    width: auto;
    display: none;
    right: 0;
    padding: 10px 25px 26px 25px;
    position: absolute;
    z-index: 999;
    -moz-box-shadow: 0 1px 10px rgba(66, 71, 75, 0.5);
    -webkit-box-shadow: 0 1px 10px rgba(66, 71, 75, 0.5);
    box-shadow: 0 1px 10px rgba(66, 71, 75, 0.5);
    top: 50px;
    background: #fff;
  }
  #header .row-main .pane-menu-menu-submenu .menu li {
    display: block;
  }
  #header .row-main .pane-menu-menu-submenu .menu li a:before {
    display: none;
  }
  #header .row-bottom .menu li {
    height: 140px;
  }
  #header .row-bottom .menu li a {
    padding-left: 5px;
    padding-right: 5px;
    background-size: 37%;
    padding-top: 82px;
    font-size: 10pt;
    height: 140px;
  }
}
@media (max-width: 767px) {
  #header .icon-menu {
    margin-bottom: 0;
    margin-top: 8px;
  }
  #header .row-main .pane-block-3 {
    float: left;
    width: 140px;
  }
  #header .row-main .pane-block-3 .block-logo img {
    width: 100%;
    max-width: 115px;
    height: auto;
  }
  #header .row-main .pane-block-3 .block-logo .logo-text {
    display: none;
  }
  #header .row-main .pane-block-1 {
    float: left;
    width: calc(100% - 140px);
  }
  #header .row-main .pane-block-1 .block-tel {
    margin-top: 5px;
  }
  #header .row-main .pane-block-1 .block-tel .tel-visible {
    display: none;
  }
  #header .row-main .pane-block-1 .block-tel .tel-icon {
    display: block;
    opacity: 1;
    transition: opacity 0.33s;
  }
  #header .row-main .pane-block-1 .block-tel .tel-icon.active {
    opacity: 0.7;
  }
  #header .row-main .pane-block-1 .block-tel .colorbox-node {
    margin-top: 5px;
  }
  #header .row-main .pane-block-1 .block-map {
    width: auto;
  }
  #header .row-main .pane-block-1 .block-map a {
    font-size: 0;
  }
  #header .row-main .pane-block-1 .block-grafic {
    display: none;
  }
  #header .row-main .pane-menu-menu-submenu {
    display: none !important;
  }
  #header .row-bottom .pane-system-main-menu {
    display: none;
  }
  #header .row-bottom .menu li {
    height: auto;
    width: 100%;
    text-align: left;
    border-right: 0;
    border-bottom: 3px solid #fff;
  }
  #header .row-bottom .menu li a {
    padding-top: 0;
    padding-left: 65px;
    min-height: 50px;
    background-position: 20px 50%;
    background-size: 28px;
    height: auto;
    line-height: 50px;
    text-align: left;
  }
  #header .row-bottom .menu li:hover a {
    background-position: 20px 50%;
    padding-top: inherit;
  }
}
@media screen and (max-width: 480px) {
  #header .row-main .pane-block-3 {
    width: 100px;
  }
  #header .row-main .pane-block-1 {
    width: calc(100% - 100px);
  }
  #header .row-main .pane-block-1 .block-tel {
    margin-left: 10px;
  }
  #header .row-main .pane-block-1 .block-tel .tel-icon {
    display: none;
  }
  #header .row-main .pane-block-1 .block-tel .colorbox-node {
    background-image: url(sprite-_png.png);
    background-position: -12px -8px;
    width: 18px;
    height: 18px;
    font-size: 0;
    line-height: 0;
    color: transparent;
    margin-top: 0;
  }
  #header .row-main .pane-block-1 .block-map {
    margin-left: 20px;
  }
  #header .row-main .pane-block-1 .block-map a {
    padding: 0;
    min-height: 0;
    width: 18px;
    height: 26px;
    background-position: 0 0;
    background-size: contain;
  }
}
.pane-block-8 .front-afpromo .slide-tell {
  margin-top: 17px;
}
@media screen and (max-width: 1224px) {
  .pane-block-8 .front-afpromo .slide-tell {
    margin-top: 21px;
  }
}
@media screen and (max-width: 992px) {
  .pane-block-8 .front-afpromo .slide-tell .tell-frblock.tell-left {
    width: auto;
    padding-right: 0;
    float: none;
    text-align: center;
  }
}
@media screen and (max-width: 992px) {
  .pane-block-8 .front-afpromo .slide-tell .tell-frblock.tell-left a {
    text-align: center;
  }
}
.pane-block-8 .front-afpromo .wrap-callback {
  padding-top: 16px;
}

.pane-block-9 .front-afpromo .slide-tell {
  margin-top: 17px;
}
@media screen and (max-width: 1224px) {
  .pane-block-9 .front-afpromo .slide-tell {
    margin-top: 21px;
  }
}
@media screen and (max-width: 992px) {
  .pane-block-9 .front-afpromo .slide-tell .tell-frblock.tell-left {
    width: auto;
    padding-right: 0;
    float: none;
    text-align: center;
  }
}
@media screen and (max-width: 992px) {
  .pane-block-9 .front-afpromo .slide-tell .tell-frblock.tell-left a {
    text-align: center;
  }
}

#footer #block-panels-mini-footer .pane-block-5 .pane-content-in {
  position: relative;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in iframe {
  display: block;
  width: calc(100% - 255px);
  margin-left: auto;
}
@media (max-width: 600px) {
  #footer #block-panels-mini-footer .pane-block-5 .pane-content-in iframe {
    width: 100%;
    margin-left: 0;
  }
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address {
  width: 255px;
  position: absolute;
  z-index: 9;
  top: 0;
  background: #fff;
  height: 100%;
  padding: 25px 50px;
  -moz-box-shadow: 0 1px 10px rgba(66, 71, 75, 0.5);
  /* drop shadow */
  -webkit-box-shadow: 0 1px 10px rgba(66, 71, 75, 0.5);
  /* drop shadow */
  box-shadow: 0 1px 10px rgba(66, 71, 75, 0.5);
  /* drop shadow */
}
@media (max-width: 600px) {
  #footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address {
    width: auto;
  }
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-adress {
  text-align: center;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-adress span {
  font-family: sommet-bold;
  display: block;
  font-size: 11pt;
  color: #565656;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-tel {
  padding-top: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 12px;
  position: relative;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-tel:before, #footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-tel:after {
  width: 105px;
  background-color: #ebebeb;
  height: 1px;
  position: absolute;
  display: block;
  left: 0;
  content: "";
  right: 0;
  margin: auto;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-tel:before {
  top: 0;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-tel:after {
  bottom: 0;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-tel a {
  display: block;
  font-size: 15.3pt;
  line-height: 22pt;
  transition: color 0.3s;
  color: #565656;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-tel a span {
  color: #a2a2a2;
  display: inline-block;
  margin-right: 5px;
  width: 39px;
  transition: color 0.3s;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-tel a:hover {
  color: #0f88bf;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-tel a:hover span {
  color: #f75543;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-grafic {
  text-align: center;
  margin-bottom: 10px;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-grafic .dt {
  line-height: 1;
  margin-bottom: 5px;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-grafic .grafic-title {
  font-family: sommet-bold;
  /*              color: #565656;*/
  font-size: 14pt;
  margin-bottom: 4px;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-grafic .day {
  font-size: 11pt;
  color: #a2a2a2;
  display: inline-block;
  margin-right: 10px;
}
#footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-grafic .time {
  display: inline-block;
  font-family: sommet-bold;
  font-size: 15pt;
  color: #565656;
}
#footer #block-panels-mini-footer .footer-main .fm-left {
  float: left;
  padding: 30px 8px 30px 80px;
  background-color: #0f88bf;
  height: 250px;
  overflow: hidden;
  width: 76%;
}
#footer #block-panels-mini-footer .footer-main .fm-left .fm-block {
  padding-left: 12px;
  padding-right: 12px;
  width: 25%;
  float: left;
}
#footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-vuz {
  margin-top: 24px;
}
#footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-vuz .wrap-fa {
  position: relative;
  overflow: hidden;
}
#footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-vuz .wrap-fa:before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  background: #f75543;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.3s;
  -webkit-transform: scale(0);
  -webkit-transition: transform 0.3s;
  top: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
}
#footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-vuz .wrap-fa:hover:before {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
}
#footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-vuz a {
  display: block;
  font-size: 11pt;
  padding-top: 97px;
  position: relative;
  height: 140px;
  font-family: sommet-bold;
  color: #fff;
  text-align: center;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
#footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-vuz.fm-block-master a {
  background: url(../../img/master.png) no-repeat 50% 20px;
}
#footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-vuz.fm-block-callback a {
  background: url(../../img/callback.png) no-repeat 50% 20px;
}
#footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-title {
  font-size: 13pt;
  color: #fff;
  margin-bottom: 10px;
}
#footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-content {
  list-style: none;
}
#footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-menu .fm-content li {
  list-style: none;
}
#footer #block-panels-mini-footer .footer-main .fm-right {
  float: left;
  width: 24%;
  height: 250px;
  text-align: center;
  padding: 50px;
  overflow: hidden;
  background-color: #fff;
  -moz-box-shadow: 0 1px 10px rgba(66, 71, 75, 0.5);
  /* drop shadow */
  -webkit-box-shadow: 0 1px 10px rgba(66, 71, 75, 0.5);
  /* drop shadow */
  box-shadow: 0 1px 10px rgba(66, 71, 75, 0.5);
  /* drop shadow */
}
#footer #block-panels-mini-footer .footer-main .fm-right img {
  display: block;
  margin: auto;
  margin-bottom: 15px;
}
#footer #block-panels-mini-footer .footer-main .fm-right .desc {
  font-size: 11pt;
  line-height: 14px;
  margin-bottom: 15px;
  color: #565656;
  font-family: sommet-bold;
}
#footer #block-panels-mini-footer .footer-main .fm-right .desc span {
  font-family: sommet-regular;
  display: block;
}
#footer #block-panels-mini-footer .footer-main .fm-right .copyright {
  font-size: 10pt;
  line-height: 14pt;
  color: #a2a2a2;
}
#footer #block-panels-mini-footer .footer-main .fm-right .copyright span {
  display: block;
}
#footer #block-panels-mini-footer .pane-block-4 {
  float: left;
  width: 100%;
  clear: both;
}
#footer #block-panels-mini-footer .pane-block-4 div.im-logo {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}
#footer #block-panels-mini-footer .pane-block-4 div.im-logo a {
  display: inline-block;
  position: relative;
}
#footer #block-panels-mini-footer .pane-block-4 div.im-logo a .im-logo-ah {
  position: absolute;
  left: 0;
}

@media (max-width: 1200px) {
  #footer #block-panels-mini-footer .footer-main .fm-left {
    padding: 20px 12px;
    height: 230px;
  }
  #footer #block-panels-mini-footer .footer-main .fm-left .fm-block {
    padding-left: 8px;
    padding-right: 8px;
  }
  #footer #block-panels-mini-footer .footer-main .fm-right {
    padding: 35px;
    height: 230px;
  }
}
@media (max-width: 992px) {
  #footer #block-panels-mini-footer .footer-main .fm-left {
    width: 100%;
    height: auto;
  }
  #footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-vuz {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  #footer #block-panels-mini-footer .footer-main .fm-right {
    width: 100%;
    height: auto;
    padding: 20px;
  }
}
@media (max-width: 767px) {
  #footer #block-panels-mini-footer .footer-main .fm-left .fm-block {
    width: 50%;
  }
}
@media (max-width: 600px) {
  #footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address {
    position: relative;
  }
  #footer #block-panels-mini-footer .pane-block-5 .pane-content-in .fmap-address .fblock-tel {
    text-align: center;
  }
  #footer #block-panels-mini-footer .footer-main .fm-left .fm-block {
    width: 100%;
  }
  #footer #block-panels-mini-footer .footer-main .fm-left .fm-block.fm-support {
    margin-bottom: 25px;
  }
  #footer #block-panels-mini-footer .footer-main .fm-right {
    box-shadow: none;
    -webkit-box-shadow: none;
    padding-bottom: 10px;
  }
}