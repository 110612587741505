//##############################################################################
// [[[[[ BEGIN ]]]]] FOOTER
//##############################################################################
#footer {
  #block-panels-mini-footer {
    @extend .container;
    .pane-block-5 {
      .pane-content-in {
        position: relative;
        
        iframe {
          display: block;
          width: calc(100% - 255px);
          margin-left: auto;
  
          @media (max-width: 600px) {
            width: 100%;
            margin-left: 0;
          }
        }
        .fmap-address {
          width: 255px;
          position: absolute;
          z-index: 9;
          top: 0;
          background: #fff;
          height: 100%;
          padding: 25px 50px;
          -moz-box-shadow: 0 1px 10px rgba(66,71,75,.5); /* drop shadow */
          -webkit-box-shadow: 0 1px 10px rgba(66,71,75,.5); /* drop shadow */
          box-shadow: 0 1px 10px rgba(66,71,75,.5); /* drop shadow */
  
          @media (max-width: 600px) {
            width: auto;
          }
          
          .fblock-adress {
            text-align: center;
            span {
              font-family: $sommetbold ;
              display: block;
              font-size: 11pt;
              color: #565656;
            }
          }
          .fblock-tel{
            padding-top: 12px;
            margin-top: 15px;
            margin-bottom: 15px;
            padding-bottom: 12px;
            position: relative;
            &:before,
            &:after {
              width: 105px;
              background-color: #ebebeb;
              height: 1px;
              position: absolute;
              display: block;
              left: 0;
              content: '';
              right: 0;
              margin: auto;
            }
            &:before {
              top: 0;
            }
            &:after {
              bottom: 0;
            }
            a {
              display: block;
              font-size: 15.3pt;
              line-height: 22pt;
              transition: color .3s;
              color: #565656;
              span {
                color: #a2a2a2;
                display: inline-block;
                margin-right: 5px;
                width: 39px;
                transition: color .3s;
              }
              &:hover {
                color: $colorblue;
                span {
                  color: $colorred;
                }
              }
            }
          }
          .fblock-grafic {
            text-align: center;
            margin-bottom: 10px;
            .dt {
              line-height: 1;
              margin-bottom: 5px;
            }
            .grafic-title {
              font-family: sommet-bold;
/*              color: #565656;*/
              font-size: 14pt;
              margin-bottom: 4px;
            }
            .day {
              font-size: 11pt;
              color: #a2a2a2;
              display: inline-block;
              margin-right: 10px;
            }
            .time {
              display: inline-block;
              font-family: $sommetbold;
              font-size: 15pt;
              color: #565656;
            }
          }
        }
      }
    }
    .footer-main {
      .fm-left {
        float: left;
        padding: 30px 8px 30px 80px;
        background-color: $colorblue;
        height: 250px;
        overflow: hidden;
        width: 76%;
        .fm-block {
          padding-left: 12px;
          padding-right: 12px;
          width: 25%;
          float: left;
          &.fm-vuz {
            margin-top: 24px;
            .wrap-fa {
              position: relative;
              overflow: hidden;
              &:before {
                content: '';
                position: absolute;
                display: block;
                left: 0;
                background: $colorred;
                right: 0;
                bottom: 0;
                margin: auto;
                border-radius: 50%;
                transform: scale(0);
                transition: transform .3s;
                -webkit-transform: scale(0);
                -webkit-transition: transform .3s;
                top: 0;
                width: 100%;
                z-index: 0;
                height: 100%;
              }
              &:hover {
                &:before {
                  transform: scale(1.5);
                  -webkit-transform: scale(1.5);
                }
              }
            }
            a {
              display: block;
              font-size: 11pt;
              padding-top: 97px;
              position: relative;
              height: 140px;
              font-family: $sommetbold;
              color: #fff;
              text-align: center;
              width: 100%;
              border: 1px solid rgba(255, 255, 255, .5);
            }
            &.fm-block-master {
              a {
                background: url(../../img/master.png) no-repeat 50% 20px;
              }
            }
            &.fm-block-callback {
              a {
                background: url(../../img/callback.png) no-repeat 50% 20px;
              }
            }
          }
          &.fm-menu {
            .fm-title{
              font-size: 13pt;
              color: #fff;
              margin-bottom: 10px;
            }
            .fm-content {
              list-style: none;
              li {
                list-style: none;
                @extend .link-effect-2;

              }
            }
          }

        }
      }
      .fm-right {
        float: left;
        width: 24%;
        height: 250px;
        text-align: center;
        padding: 50px;
        overflow: hidden;
        background-color: #fff;
        -moz-box-shadow: 0 1px 10px rgba(66,71,75,.5); /* drop shadow */
        -webkit-box-shadow: 0 1px 10px rgba(66,71,75,.5); /* drop shadow */
        box-shadow: 0 1px 10px rgba(66,71,75,.5); /* drop shadow */
        img {
          display: block;
          margin: auto;
          margin-bottom: 15px;
        }
        .desc {
          font-size: 11pt;
          line-height: 14px;
          margin-bottom: 15px;
          color: #565656;
          font-family: $sommetbold;
          span {
            font-family: $sommetregular;
            display: block;
          }
        }
        .copyright {
          font-size: 10pt;
          line-height: 14pt;
          color: #a2a2a2;
          span {
            display: block;
          }
        }
      }
    }
    .pane-block-4 {
      float: left;
      width: 100%;
      clear: both;
      div.im-logo {
        text-align: center;
        margin-top: 25px;
        margin-bottom: 25px;
        a {
          display: inline-block;
          position: relative;
          @extend .logo-effect;
          img.im-logo {
            @extend .logo-eff-visible;
          }
          .im-logo-ah {
            @extend .logo-eff-hidden;
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  #footer {
    #block-panels-mini-footer {
      .footer-main {
        .fm-left {
          padding: 20px 12px;
          height: 230px;
          .fm-block {
            padding-left: 8px;
            padding-right: 8px;
          }
        }
        .fm-right {
          padding: 35px;
          height: 230px;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  #footer {
    #block-panels-mini-footer {
      .footer-main {
        .fm-left {
          width: 100%;
          height: auto;
          .fm-block {
            &.fm-vuz {
              margin-top: 12px;
              margin-bottom: 12px;
            }
          }
        }
        .fm-right {
          width: 100%;
          height: auto;
          padding: 20px;
        }
      }
    }
  }
}


@media (max-width: 767px) {
  #footer {
    #block-panels-mini-footer {
      .footer-main {
        .fm-left {
          .fm-block {
            width: 50%;
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  #footer {
    #block-panels-mini-footer {
      .pane-block-5 {
        .pane-content-in {
          .fmap-address {
            position: relative;
            .fblock-tel {
              text-align: center;
            }
          }
        }
      }
      .footer-main {
        .fm-left {
          .fm-block {
            width: 100%;
            &.fm-support {
              margin-bottom: 25px;
            }
          }
        }
        .fm-right {
          box-shadow: none;
          -webkit-box-shadow: none;
          padding-bottom: 10px;
        }
      }
    }
  }
}
// [[[[[ BEGIN ]]]]] FOOTER
//##############################################################################